//获取改签单详情
import consumer_t_od_trains_order_change_getTrainsChangeOrderDetailV2 from '@/lib/data-service/haolv-default/consumer_t_od_trains_order_change_getTrainsChangeOrderDetailV2'
import consumer_insurance_getEInsuranceOrderUrl from '@/lib/data-service/haolv-default/consumer_insurance_getEInsuranceOrderUrl'
//获取审批进度
import consumer_trains_order_getSchedule from '@/lib/data-service/haolv-default/consumer_trains_order_getSchedule'
import moment from "moment";
import {awaitWrap} from '@/page/admin/company-management/staff-management/common/unit'
export default {
    data () {
        return {
            orderNo: '',
            orderInfo: {},
            scheduleList: [],
            // showProgress: true,
            showProgress: false,
            //改签单信息
            changeOrderInfo:{},
            //原车次信息
            originalTrainInfo:{},
            //改签车次信息
            changeTrainInfo:{},
            //改签乘客
            changePassengerInfo: [],
            //保险信息
            insuranceInfo: [],
            routeName: '',
        }
    },
    components: {},
    created () {

    },
    mounted () {
        this.routeName = this.$route.name;
    },
    async activated () {
        this.orderNo = this.$route.query.orderNo;
        await this.getDetail();
    },
    deactivated() {},
    destroyed () {},
    watch: {},
    computed: {},
    filters: {
        filterApproveStatus(val) {
            // 0.未开始，1.待我审，2.审核通过，3.驳回，4.已失效
            if (val === 0) {
                return '未开始'
            } else if (val === 1) {
                return '待我审'
            } else if (val === 2) {
                return '审核通过'
            } else if (val === 3) {
                return '驳回'
            } else if (val === 4) {
                return '已失效'
            } else {
                return ''
            }
        },
    },
    methods: {
        clickInsurance(val){
            if (val.insuranceOrderId) {
                let data = {
                    insuranceOrderId: val.insuranceOrderId,
                };
                consumer_insurance_getEInsuranceOrderUrl(data).then(res => {
                    const __this = this;
                    const eleLink = document.createElement('a');
                    eleLink.href = res.datas.epolicyUrl;
                    eleLink.target = '_blank';
                    document.body.appendChild(eleLink);
                    eleLink.click();
                    document.body.removeChild(eleLink);
                })
            } else {
                this.$message.error('正在生成保单信息，请稍后查询...');
            }

        },
        clickSpan(){
            if (this.$route.meta.data_for_back_layout && this.$route.meta.data_for_back_layout.layoutType === 'orderCenterType') {
                this.$router.push({
                    name:'admin-order-center-train-change-list'
                })
            } else {
                this.$router.push({
                    name:'admin-my-order-train-change-list'
                })
            }
        },
        gmtStartFormatter(val) {
            return this.$moment(val.gmtStart).format('YYYY-MM-DD HH:mm')
        },
        gmtArriveFormatter(val) {
            return this.$moment(val.gmtArrive).format('YYYY-MM-DD HH:mm')
        },
        checkEvection() {
            this.$router.push({
                name: 'admin-my-apply-manage-apply-info',
                query: {
                    applyId: this.orderInfo.applyId,
                    type: 'info',
                    approveType: 1,
                    lastMenu: 'adminMyApply'
                }
            })
        },
        checkChangeEvection() {
            this.$router.push({
                name: 'admin-my-apply-manage-apply-info',
                query: {
                    applyId: this.orderInfo.changeApplyId,
                    type: 'info',
                    approveType: 4,
                    lastMenu: 'adminMyApply'
                }
            })
        },
        async getDetail() {
            const loading = this.$loading({
                lock: true,
                text: '',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            let [err, res] = await awaitWrap(consumer_t_od_trains_order_change_getTrainsChangeOrderDetailV2({changeNo: this.orderNo}))
            this.changeOrderInfo = res.datas.changeOrderInfo;
            this.originalTrainInfo = res.datas.originalTrainInfo;
            this.changeTrainInfo = res.datas.changeTrainInfo;
            this.changePassengerInfo = res.datas.changePassengerInfo;
            this.insuranceInfo = res.datas.insuranceInfo;

            console.log(this.changeOrderInfo);
            console.log(this.originalTrainInfo);
            console.log(this.changeTrainInfo);
            console.log(this.changePassengerInfo);
            console.log(this.insuranceInfo);

            if (err) {
                loading.close();
                return
            }
            loading.close();
            let orderInfo = res.datas;
            let trainItemInfo = {
                trainNo: orderInfo.originalTrainCode, // 车次
                fromStation: orderInfo.originalChangeFromStationName, // 出发站
                toStation: orderInfo.originalChangeToStationName, // 到达站
                seatName: orderInfo.originalSeatName, // 坐席
                startTime: orderInfo.originalChangeStartDate, //出发时间
                arriveTime: orderInfo.originalChangeToDate, // 到达时间
                ticket: orderInfo.electronicTicketNumber
            };
            orderInfo.orderTrainArr = [trainItemInfo];
            //审批进度信息是否展示判断
            // if (!orderInfo.applyId) {
            //     this.showProgress = false
            // }
            this.orderInfo = orderInfo
        },
        async getSchedule() {
            //判断改签详情的接口是否有申请单(审批进度)
            if (!this.orderInfo.applyNo) {
                return
            }
            let res = await consumer_trains_order_getSchedule({applyNo: this.orderInfo.applyNo})
            this.scheduleList = res.datas
        },
        startTimeFormatter(val) {
          return this.$moment(val.startTime).format('YYYY-MM-DD HH:mm')
        },
        arriveTimeFormatter(val) {
            return this.$moment(val.arriveTime).format('YYYY-MM-DD HH:mm')
        },
        back() {
            this.$router.back();
        }
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
